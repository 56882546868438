// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  softwareVersion: 1,
  production: false,
  isolation: '__',
  itemsTimeoutInSeconds: 3600,
  sessionTimeout: 91113600,
  subscribeDashboardSummaryOrderStatus: false,
  subscribeDashboardCompletedOrders: false,
  isEnableHomePage: false,
  imageDomain: 'bideal-app.com',
  adminEmail: 'versita.store.bd.prd@gmail.com',
  adminPassword: 'afykqhzyfqmcspag',
  encryptKey: '!Zw8q4ZNc)wuZRDz*LC%8BE?Fr>PK_G:',
  isPhoneEnable: true,
  isEmailEnable: false,
  firebase: {
    apiKey: 'AIzaSyAkTxG0eRs94eFa23Q7lUbg9x9nePCXDyA',
    authDomain: 'bideal-app.com',
    databaseURL: 'https://versita-store-bd-prd.firebaseio.com',
    projectId: 'versita-store-bd-prd',
    storageBucket: 'versita-store-bd-prd.appspot.com',
    measurementId: "G-EBPZM7C1K6",
    messagingSenderId: "407100416604",
    appId: '1:407100416604:web:7d330af20eb8597fe8bc99'
  }
};
