import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {DateFormat} from "../../utils/datetime";
import {AesHelper} from "../../shared-preference/aes-helper";
import {catchError} from "rxjs/operators";
import {HttpError} from "../../utils/httpError";
import {environment} from "../../../environments/environment";
import {ItemStock} from "../../model/item-stock";
import {LoggUtilInfo} from "../../utils/loggUtil";
import {Item} from "../../inventory/model/item";
import {StringUtils} from "../../utils/string-utils";
import {BidItem} from '../../inventory/model/bidding';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }
  protected authDomain = `https://${environment.firebase.authDomain}`;
  public static endPoint = "";

  public requestReadItem(storeId: string, id: string){
    const apiItemUrl = `${this.authDomain}/cdn/item/${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(storeId, null, true),
        "content-type": "application/json"
      })
    };

    console.info(`onRead from id:: ${id} storeId: ${storeId} : `,  {"url": apiItemUrl});

    if(!storeId)
      return this.http.get(apiItemUrl)
        .pipe(catchError(HttpError.handleError));

    return this.http.get(apiItemUrl, httpOptions)
      .pipe(catchError(HttpError.handleError));
  }

  public requestInventoryStock(storeId: string = '200324035600', from: string = '1620039264', to: string = '1622717664'){
    const apiStockUrl = `${this.authDomain}/api/stock?from=${from}&to=${to}`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(storeId, null),
        "content-type": "application/json"
      })
    };

    console.info(`onRead from storeId: ${storeId} : `,  {"url": apiStockUrl});

    return this.http.get(apiStockUrl, httpOptions)
      .pipe(catchError(HttpError.handleError));
  }

  public requestSearchOrder(keyword: string){
    const apiUrl = `${this.authDomain}/order/search?keyword=${keyword}`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(),
        "content-type": "application/json"
      })
    };

    console.info(`onRead from id:: ${keyword} : `,  {"url": apiUrl});

    return this.http.get(apiUrl, httpOptions)
      .pipe(catchError(HttpError.handleError));
  }

  public requestFilterByBrandAndCategory(isBrand: boolean = true, id: string){
    const doc = isBrand ? 'brand' : 'category';
    const apiItemUrl = `${this.authDomain}/api/related?${doc}=${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json"
      })
    };

    console.info(`onRead from id: ${id} : `,  {"url": apiItemUrl});

    return this.http.get(apiItemUrl, httpOptions)
      .pipe(catchError(HttpError.handleError));
  }

  public requestReadItemAdmin(storeId: string = null, lastId: string = null, keyword: string = null, enable: boolean = null, saleType: string[] = null, brandId: string = null, categoryId: string = null, ranking: string = null, isExpired = null){

    let url = `https://${environment.firebase.authDomain}/api/item?store=${storeId}`;

    if(lastId !== null && lastId !== '') {
      url = url + `&lastid=${lastId}`;
    } else {
      url = url + `&ts=${DateFormat.getTimeStamp()}`;
    }

    if(keyword !== null && keyword !== '')
      url = url + `&search=${keyword}`;

    if(enable !== null && enable.toString() !== '')
      url = url + `&enable=${enable}`;

    if(saleType !== null && !saleType.includes('' || null))
      url = url + `&saletype=${saleType}`;

    if(brandId !== null && brandId !== '')
      url = url + `&brand=${brandId}`;

    if(categoryId !== null && categoryId !== '')
      url = url + `&category=${categoryId}`;

    if(ranking !== null && ranking !== '')
      url = url + `&ranking=${ranking}`;

    if(isExpired !== null && isExpired !== '')
      url = url + `&expire=${isExpired}`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(),
        "content-type": "application/json"
      })
    };

    console.info(`requestReadItemAdmin from storeId: ${storeId} : `,  {"url": url});

    return this.http.get(url, httpOptions)
      .pipe(catchError(HttpError.handleError));
  }

  public requestExportItemLabel(url: string, storeId: string){
    url = url + "&token=" + encodeURIComponent(ApiService.generateToken());
    console.info(`requestExportItemLabel from storeId: ${storeId} : `,  {"url": url});

    if(url){
      return this.http.get(url, {responseType: 'blob' as 'json'})
        .pipe(catchError(HttpError.handleError));
    }
  }

  public requestFilterItem(storeId: string = null, keyword: string = null, lastId: string = null, enable: boolean = null, saleType: string[] = null, brandId: string = null, categoryId: string = null, ranking: string = null, isExpired = null){

    let url = `${this.authDomain}/cdn/items?`;

    if(lastId !== null && lastId !== '')
      url = url + `&lastid=${lastId}`;

    if(keyword !== null && keyword !== '')
      url = url + `&search=${keyword}`;

    if(enable !== null && enable.toString() !== '')
      url = url + `&enable=${enable}`;

    if(saleType !== null && !saleType.includes('' || null))
      url = url + `&saletype=${saleType}`;

    if(brandId !== null && brandId !== '')
      url = url + `&brand=${brandId}`;

    if(categoryId !== null && categoryId !== '')
      url = url + `&category=${categoryId}`;

    if(ranking !== null && ranking !== '')
      url = url + `&ranking=${ranking}`;

    if(isExpired !== null && isExpired !== '')
      url = url + `&expire=${isExpired}`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(),
        "content-type": "application/json"
      })
    };

    console.info(`requestFilterItem from storeId: ${storeId} : `,  {"url": url});

    return this.http.get(url, httpOptions)
      .pipe(catchError(HttpError.handleError));
  }

  public requestFilterBiddingItem(id: string = null, keyword: string = null){

    let url = `${this.authDomain}/api/bid/item`;
    // let url = `http://0.0.0.0:5001/versita-store-bd-preprd/us-central1/bidItem`;

    if(id !== null && id !== '')
      url = url + `?id=${id}`;

    if((id === null || id === '') && keyword !== null && keyword !== '')
      url = url + `?q=${keyword}&query_by=status,keywords,type`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(),
        "content-type": "application/json"
      })
    };

    console.info(`requestFilterBiddingItem  : `,  {"url": url});

    return this.http.get(url, httpOptions)
      .pipe(catchError(HttpError.handleError));
  }

  public requestCreateItem(data){
    const apiItemUrl = `${this.authDomain}/api/item`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(),
        "content-type": "application/json"
      })
    };

    console.info(`onCreate to storeId: ${data.storeId} ==> data : `,  {"url": apiItemUrl, "data": data});

    return this.http.post(apiItemUrl, data, httpOptions)
      .pipe(catchError(HttpError.handleError));
  }

  public requestUpdateItem(id: string = null, data){
    const apiItemUrl = `${this.authDomain}/api/item`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(id, data.storeId),
        "content-type": "application/json"
      })
    };

    console.info(`onUpdate to id ${id} storeId: ${data.storeId} ==> data : `,  {"url": apiItemUrl, "data": data});
    return this.http.post(apiItemUrl, data, httpOptions)
      .pipe(catchError(HttpError.handleError));
  }

  public static generateToken(id: string = null, storeId: string = null, isNoTimeStamp: boolean = false, expirationInSec: number = 60, isDelete: boolean = false , barcodes?: string[], isGetAllItem: boolean = false, orderId: string = null): string{

    try {
      let expirationTimestamp = DateFormat.getTimeStamp(expirationInSec);

      let headerData = {
        "id": id != null ? id : true,
        "expiration": isNoTimeStamp ? '' : expirationTimestamp
      };

      if(storeId){
        headerData["storeid"] = storeId;
      }

      if(orderId){
        headerData["orderid"] = orderId;
      }

      if(isDelete){
        if (barcodes && barcodes.length > 0){
          headerData['barcodes'] = barcodes;
        } else {
          headerData['barcodes'] = [];
        }
      }

      if(isGetAllItem){
        headerData['size'] = "null";
      }

      return AesHelper.encrypt(JSON.stringify(headerData));
    }catch (e) {
      return null;
    }
  }


  public requestLowInventory(storeId : string){

    const url = `${this.authDomain}/api/request/inventory?store=${storeId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(),
        "content-type": "application/json"
      })
    };

    LoggUtilInfo.loggingInfo("Request Low Inventory", url);
    return this.http.get(url, httpOptions)
      .pipe(
        catchError(HttpError.handleError)
      ).toPromise().then(value => {
        let itemStocks : ItemStock[] = [];
        console.log("Result request low inventory", value);
        if(value['code'] === 200){
          Array.from(value['result']['data']).forEach(e => {
            let itemStock : ItemStock = new ItemStock();
            itemStock.fromJson(e);
            itemStocks.push(itemStock);
          });
          return itemStocks;
        }
        return itemStocks;
      });
  }

  public requestDeleteItem(id : string, barcodes : string[]){

    const apiItemUrl = `${this.authDomain}/api/item`;
    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(id,null,false,60, true, barcodes),
        "content-type": "application/json",
      })
    };

    console.info(`onDelete item id : ${id} ==> barcodes : `,  {"url": apiItemUrl, "barcodes": barcodes});
    return this.http.delete(apiItemUrl, httpOptions)
      .pipe(catchError(HttpError.handleError));
  }

  public requestReadAllItem(storeId: string){
    const apiItemUrl = `${this.authDomain}/api/item?store=${storeId}&token=${encodeURIComponent(ApiService.generateToken(null,null,false,60, false, [], true))}`;

    console.info(`onRead from storeId: ${storeId} : `,  {"url": apiItemUrl});

    return this.http.get(apiItemUrl)
      .pipe(
        catchError(HttpError.handleError)
      );
  }

  public requestUpdateItemDiscountByBrand(id: string, data: any){
    const brandDiscountEndpoint = `${this.authDomain}/api/request/discount`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(id),
        "content-type": "application/json"
      })
    };

    return this.http.post(brandDiscountEndpoint, data, httpOptions)
      .pipe(
        catchError(HttpError.handleError)
      );
  }

  public requestEnableDisableStore(id: string, data: any){
    const enableDisableStore = `${this.authDomain}/api/store/golive`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(id),
        "content-type": "application/json"
      })
    };

    return this.http.post(enableDisableStore, data, httpOptions)
      .pipe(
        catchError(HttpError.handleError)
      );
  }

  public requestImportItem(items : Item[], isNew : boolean){
    const importUrl = `${this.authDomain}/api/product/import`;
    let storeId = items[0].storeId;
    let mapItems = items.reduce((key, item) => {
      let itemObj: Map<string, any>;
      itemObj = StringUtils.objToStrMap(item.toMap(true));
      if (isNew) {
        itemObj.delete("id");
      }else{
        if (itemObj.get("id").toString().includes("'")) {
          itemObj.set("id", itemObj.get("id").split("'")[1]);
        }
      }

      if (itemObj.get("storeId").toString().includes("'")) {
        itemObj.set("storeId", itemObj.get("storeId").split("'")[1]);
        storeId = itemObj.get("storeId");
      }

      if (itemObj.get("brand").toString().includes("'") && itemObj.get("brand").split("'")[1] != undefined  && !isNaN(itemObj.get("brand").split("'")[1])) {
        itemObj.set("brand", itemObj.get("brand").split("'")[1]);
      }

      if (itemObj.get("category").toString().includes("'") && itemObj.get("category").split("'")[1] != undefined && !isNaN(itemObj.get("category").split("'")[1])) {
        itemObj.set("category", itemObj.get("category").split("'")[1]);
      }

      key.push(StringUtils.strMapToObj(itemObj));
      return key;
    }, []);

    console.info(`data upload ==>  `,  mapItems);
    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(storeId, null, false, 540),
        "content-type": "application/json"
      })
    };
    return this.http.post(importUrl, {"items" : mapItems}, httpOptions)
      .pipe(
        catchError(HttpError.handleError)
      );
  }

  public requestPlatforms(bundleId: string, storeId: string) {
    let url  = `${this.authDomain}/api/platforms`;
    if(bundleId !== null && bundleId !== '')
      url = url + `?id=${bundleId}`;

    if(storeId !== null && storeId !== '')
      url = url + `?store=${storeId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(),
        "content-type": "application/json"
      })
    };

    console.info(`requestPlatforms from storeId: ${storeId} : `,  {"url": url});

    return this.http.get(url, httpOptions)
      .pipe(catchError(HttpError.handleError));

  }

  public requestUpdateOrderStatus(id: string, storeId: string, orderId: string, body) {

    let url  = `${this.authDomain}/order/updateStatus`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(id,storeId,false,120, false, [], null, orderId),
        "content-type": "application/json"
      })
    };


    console.info({"url": url});
    return this.http.post(url, body, httpOptions)
      .pipe(catchError(HttpError.handleError));

  }


  public requestUpdateStock(data, id){
    let url  = `${this.authDomain}/api/stock`;
    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(id),
        "content-type": "application/json"
      })
    };

    console.info(`update stock variation id: ${data.barcode}`,  {"url": url, "data": data});

    return this.http.post(url, data, httpOptions)
      .pipe(catchError(HttpError.handleError));
  }

  public requestUpdateTempOrderToOrder(id : string){
    let url  = `${this.authDomain}/order/payback`;
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json"
      })
    };
    return this.http.post(url, JSON.stringify({"tran_id" : id, "status" : 0}), httpOptions).pipe(catchError(HttpError.handleError));
  }

  public requestCreateOrUpdateBidding(body, id: string){

    console.info(`body:`, body);

    let url  = `${this.authDomain}/api/bid/item`;
    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(),
        "content-type": "application/json"
      })
    };

    if([undefined, null, ''].includes(id))
      return this.http.post(url, body, httpOptions).pipe(catchError(HttpError.handleError));
    else
      return this.http.put(url, body, httpOptions).pipe(catchError(HttpError.handleError));

  }

  public requestReadBidding(id: string){

    let apiItemUrl =  `${this.authDomain}/api/bid/item?id=${id}`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(),
        "content-type": "application/json"
      })
    };

    console.info(`onRead from id:: ${id}`,  {"url": apiItemUrl});

    return this.http.get(apiItemUrl, httpOptions).pipe(catchError(HttpError.handleError));
  }


  public requestDepositAndWithdraw(body = {}){

    let apiUrl =  `${this.authDomain}/api/transaction`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(),
        "content-type": "application/json"
      })
    };

    return this.http.post(apiUrl, body, httpOptions).pipe(catchError(HttpError.handleError));

  }

  public requestUpdateTransaction(body = {}){

    let apiUrl =  `${this.authDomain}/api/transaction`;

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(),
        "content-type": "application/json"
      })
    };

    return this.http.put(apiUrl, body, httpOptions).pipe(catchError(HttpError.handleError));

  }

  public requestReadTransaction(id: string, page: number, status?: string, type?: string, payMethod?: string, isFindTranId = false, isFromWallet = false, startDate?: string, endDate?: string, filterByDate?: string) {
    let query = "";
    let byDate = isFromWallet ? filterByDate : `createdAt:[${startDate}..${endDate}]`;
    let newStatus = status ? `status:=${status}` : undefined;
    let newType = type ? `type:=${type}` : undefined;
    let newPaymentMethod = payMethod ? `payment.method:=${payMethod}` : undefined;

    let filterList = [newStatus, newType, newPaymentMethod].filter(n => n).join('&&');

    if(id === 'system'){
      //this line using when filter
      query = `filter_by=${encodeURIComponent(`${filterList}`)}`;

      //if has startDate && endDate it mean use for export
      if(startDate && endDate){
        query = `filter_by=${encodeURIComponent(`${byDate}${isFromWallet ? '&&status:=approved' : ''}`)}`;
      }
    }else {
      query = `query_by=uid&q=${id}`;
      //if has startDate && endDate it mean use for export
      if(startDate && endDate){
        query += `&filter_by=${encodeURIComponent(`${byDate}${isFromWallet ? '&&status:=approved' : ''}`)}`;
      }else {
        //this else using when filter
        if(type || status || payMethod)
          query += `&filter_by=${encodeURIComponent(`${filterList}`)}`;
      }
    }

    if(isFindTranId){
      let newId = id.split('-search-')[1];
      query = `query_by=_id,metadata.bidId,phoneNumber&q=${newId}`;

      if(id === 'system-search-system'){
        query = `query_by=uid&q=${newId}`;
      }
    }

    let per_page = startDate && endDate ? `&per_page=250` : '';
    if(page && ['&per_page=250', ''].includes(per_page)){
      query += `&page=${page}`;
    }

    let apiUrl = `${this.authDomain}/api/transaction?${query}${per_page}`;
    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(),
        "content-type": "application/json"
      })
    };

    return this.http.get(apiUrl, httpOptions).pipe(catchError(HttpError.handleError));
  }


  public requestPushItemBid(bidItem : BidItem, img: string, uidsWinner : string[], uidsLose: string[]){
    const status = bidItem.status;
    const apiItemUrl = `${this.authDomain}/api/pushItemBid`;

    let body = {
      "status" : status,
      "bidItemId" : bidItem.id,
      "image" : img,
      "title" : {
        "en" : bidItem.title.get("en"),
        "kh" : bidItem.title.get("kh")
      }
    };

    if(status === "closed bid"){
      body['uidsWinner'] = uidsWinner;
      body['uidsLose'] = uidsLose;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        "token": ApiService.generateToken(),
        "content-type": "application/json"
      })
    };

    console.info(`onCreate to storeId: ${status} ==> data : `,  {"url": apiItemUrl, "data": body});

    return this.http.post(apiItemUrl, body, httpOptions).pipe(catchError(HttpError.handleError));
  }
}
